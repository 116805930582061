import { booksMap, fetchBiblesBooks } from "./bibles-books.utils";
import { biblesColl, fetchBiblesTransl } from "./bibles-transl.utils";

export async function setupBible() {
    const bibles = await fetchBiblesTransl();
    const bibleCodes = bibles.map(el => el.bibleCode);
    
    await fetchBiblesBooks({ bibleCodes });

    // assign books to bibles transl
    biblesColl.value.map(bible => { 
        bible.booksColl = booksMap.value[bible.bibleCode];
    }) 
}