export const biblePlansColl = ref([
    {
        plan_slug: 'pehi',
        plan_name: 'The Pentateuch and the history of Israel',
        plan_descr_en: 'This wonderful 365-day reading plan will take you through the first five books of Moses and the historical books that reveal the early history of the Israelite people. During this time, you will learn about the creation of the Earth, the creation of the first humans Adam and Eve, the circumstances of the fall of humanity, the reasons for the flood, delve into the period of the patriarchs, judges, and the first kings of Israel.',
        plan_descr_uk: 'Цей чудовий план читання за 365 днів проведе вас через перші п’ять книг Моїсея та історичні книги, які розкривають ранню історію Ізраїльского народу. За цей час ви дізнаєтесь історію виникнення Землі, створення перших людей Адама та Єви, обставини гріхопадіння людини, причини водного потопу, зануритесь у період патріархів, суддів та перших царів Ізраїлю.',
        plan_descr_ru: 'Этот замечательный план чтения за 365 дней проведет вас через первые пять книг Моисея и исторические книги, которые раскрывают раннюю историю Израильского народа. За это время вы узнаете историю возникновения Земли, создание первых людей Адама и Евы, обстоятельства грехопадения человека, причины водного потопа, погрузитесь в период патриархов, судей и первых царей Израиля.',
        plan_image: '/images/read-plans/pentateuch.jpg',
        plan_days: 365,
    },
    {
        plan_slug: 'chpr',
        plan_name: 'Chronicles and prophets',
        plan_descr_en: 'The books of Chronicles and the prophets will unfold before you the detailed history of Israel recorded in the historical books of Chronicles, Nehemiah, Ezra, and Esther. The books of the major prophets Isaiah, Ezekiel, and Jeremiah will take you through periods of struggle, captivity, and the liberation of the Israelite people during the times of the Assyrian Empire and the Babylonian Kingdom. And the books of the minor prophets: Daniel, Habakkuk, Zechariah, Nahum, Zephaniah, Hosea, Joel, Amos, Habakkuk, and Micah, will reveal prophecies from the time of the Babylonian Empire to the first coming of Jesus Christ.',
        plan_descr_uk: 'Книги Хронік та пророків розгорнуть перед вами детальну історію Ізраїлю записану у історичних книгах Хронік, Неємії, Ездри та Естер. Книги великих пророків Ісаї, Езекіїля та Єремії проведуть вас через періоди боротьби, полону та визволення Ізраїльского народу у часи Асирійської імперії та Вавилонського царства. Та книги малих пророків: Даниила, Авакума, Захарії, Наума, Софонії, Осії, Йоїла, Амоса, Авакума та Міхея, розкриють пророцтва від часів Вавилонської імперії до першого приходу Ісуса Христа.',
        plan_descr_ru: 'Книги Паралипоменона и пророков развернут перед вами подробную историю Израиля записанную в исторических книгах Паралипоменона, Неемии, Ездры и Есфирь. Книги великих пророков Исаии, Иезекииля и Иеремии проведут вас через периоды борьбы, пленения и освобождения Израильского народа во времена Ассирийской империи и Вавилонского царства. И книги малых пророков: Даниила, Аввакума, Захарии, Наума, Софонии, Осии, Иоиля, Амоса, Аввакума и Михея, раскроют пророчества от времен Вавилонской империи до первого пришествия Иисуса Христа.',
        plan_image: '/images/read-plans/chronicles.jpg',
        plan_days: 365,
    },
    {
        plan_slug: 'pswl',
        plan_name: 'Psalms and wisdom literature',
        plan_descr_en: 'Over 365 days, the practical lessons of God\'s wisdom, the depth of eternal values, and the meaning of life will be revealed to you in the poetic books of Ecclesiastes and Proverbs by King Solomon. This plan also covers the world of Psalms, where you will find sincere songs and fervent prayers of kings David, Solomon, and Moses, instructive psalms of Asaph and the sons of Korah.',
        plan_descr_uk: 'За 365 днів у поетичних книгах Еклезіяста та Приповістях царя Соломона перед вами відкриються практичні уроки Божої премудрості, глибина вічних цінностей та змісту життя. Цей план також охоплює світ книги Псалмів, де ви знайдете щирі пісні та палкі молитви царів Давида, Соломона та Мойсея, повчальні псалми Асафа та синів Коре́євих.',
        plan_descr_ru: 'За 365 дней в поэтических книгах Екклесиаста и Притчах царя Соломона перед вами откроются практические уроки Божьей мудрости, глубина вечных ценностей и смысла жизни. Этот план также охватывает мир книги Псалмов, где вы найдете искренние песни и горячие молитвы царей Давида, Соломона и Моисея, поучительные псалмы Асафа и сыновей Кораховых.',
        plan_image: '/images/read-plans/psalms.jpg',
        plan_days: 365,
    },
    {
        plan_slug: 'goep',
        plan_name: 'Gospels and Epistles',
        plan_descr_en: 'This plan includes all the books of the New Testament. It covers the history of the birth, ministry, death, and resurrection of the Savior - Jesus Christ as described in the Gospels of the apostles Matthew, Mark, John, and the physician Luke. The reading continues with the description of the life of the apostolic Church of the first century, includes the letters of the apostles of Jesus Christ, and the prophetic book of Revelation by John the theologian, which reveals God\'s final victory over evil.',
        plan_descr_uk: 'Цей план включає усі книги Нового Заповіту. Він охоплює історію народження, служіння, смерті та воскресіння Спасителя - Ісуса Христа яка описана у евангеліях апостолів Матвія, Марка, Івана та лікаря Луки. Читання продовжується описом життя апостольскої Церкви першого століття, включає послання апостолів Ісуса Христа та пророчу книгу Об‘явлення Івана богослова, яка відкриває кінцеву перемогу Бога над злом.',
        plan_descr_ru: 'Этот план включает все книги Нового Завета. Он охватывает историю рождения, служения, смерти и воскресения Спасителя - Иисуса Христа, которая описана в евангелиях апостолов Матфея, Марка, Иоанна и врача Луки. Чтение продолжается описанием жизни апостольской Церкви первого века, включает послания апостолов Иисуса Христа и пророческую книгу Откровение Иоанна богослова, которая открывает конечную победу Бога над злом.',
        plan_image: '/images/read-plans/gospels.jpg',
        plan_days: 365,
    },
    {
        plan_slug: 'chbi',
        plan_name: 'Chronological Bible',
        plan_descr_en: 'The reading plan is structured to familiarize you with the events described in the Bible in chronological order over 365 days. In this way, you will discover the Bible as a continuous history starting from the creation of the first earth and the first humans, their fall, the plan of salvation, to God\'s complete victory over evil on the renewed earth.',
        plan_descr_uk: 'Читання плану побудовано таким чином, щоби ознайомитись із подіями описаними у Біблії у хронологічному порядку за 365 днів. У такий спосіб ви зможете розкрити для себе Біблію як суцільну історію починаючи від створення першої землі та перших людей, їхнього гріхопадіння, плану спасіння та до повної перемоги Бога над злом на оновленій землі.',
        plan_descr_ru: 'Чтение плана построено таким образом, чтобы ознакомиться с событиями описанными в Библии в хронологическом порядке за 365 дней. Таким образом вы сможете раскрыть для себя Библию как сплошную историю начиная от сотворения первой земли и первых людей, их грехопадения, плана спасения и до полной победы Бога над злом на обновленной земле.',
        plan_image: '/images/read-plans/chronological.jpg',
        plan_days: 365,
    }
])