
import { defaultBible, defaultBook, defaultChapter } from "../useBible";
import { defaultEnableVerseParallel, defaultEnableStrong, defaultFontSize, defaultLanguage, defaultTheme, defaultEnableVerseNumbers, defaultEnableFootnotes, defaultFontFamily } from "./defaults";
import { parseValue, serializeValue } from "./utils";

const settings = reactive<any>({
    themeClass: defaultTheme,
    language: defaultLanguage,
    enableStrong: defaultEnableStrong
});

function loadSettings() {
    settings.themeClass = parseValue(localStorage.getItem('theme') || defaultTheme);
    settings.language = parseValue(localStorage.getItem('language') || defaultLanguage);

    settings.fontSize = parseValue(localStorage.getItem('fontSize') || defaultFontSize);
    settings.fontFamily = parseValue(localStorage.getItem('fontFamily') || defaultFontFamily);

    settings.useParallel = parseValue(localStorage.getItem('useParallel') || defaultBible);
    settings.useBible = parseValue(localStorage.getItem('useBible') || defaultBible);
    settings.useBook = parseValue(localStorage.getItem('useBook') || defaultBook);
    settings.useChapter = parseValue(localStorage.getItem('useChapter') || defaultChapter);

    try { settings.useHistory = parseValue(localStorage.getItem('useHistory') || []); }
    catch {
        settings.useHistory = [];
        localStorage.removeItem('useHistory');
    }

    settings.enableStrong = parseValue(localStorage.getItem('enableStrong') || defaultEnableStrong);
    settings.enableVerseParallel = parseValue(localStorage.getItem('enableVerseParallel') || defaultEnableVerseParallel);
    settings.enableVerseNumbers = parseValue(localStorage.getItem('enableVerseNumbers') || defaultEnableVerseNumbers);
    settings.enableFootnotes = parseValue(localStorage.getItem('enableFootnotes') || defaultEnableFootnotes);

}

function saveSettings() {
    localStorage.setItem('theme', serializeValue(settings.themeClass));
    localStorage.setItem('language', serializeValue(settings.language));

    localStorage.setItem('fontSize', serializeValue(settings.fontSize));
    localStorage.setItem('fontFamily', serializeValue(settings.fontFamily));

    localStorage.setItem('useParallel', serializeValue(settings.useParallel));
    localStorage.setItem('useBible', serializeValue(settings.useBible));
    localStorage.setItem('useBook', serializeValue(settings.useBook));
    localStorage.setItem('useChapter', serializeValue(settings.useChapter));
    localStorage.setItem('useHistory', serializeValue(settings.useHistory));

    localStorage.setItem('enableStrong', serializeValue(settings.enableStrong));
    localStorage.setItem('enableVerseParallel', serializeValue(settings.enableVerseParallel));
    localStorage.setItem('enableVerseNumbers', serializeValue(settings.enableVerseNumbers));
    localStorage.setItem('enableFootnotes', serializeValue(settings.enableFootnotes));
}

export function useSettings() {
    return {
        loadSettings,
        saveSettings,
        settings
    }
}