export function getPaddings(htmlElement: any) {
    const cs = getComputedStyle(htmlElement);
    return {
        top: parseInt(cs.paddingTop),
        left: parseInt(cs.paddingLeft),
        bottom: parseInt(cs.paddingBottom),
        right: parseInt(cs.paddingRight),
        horizontal: parseInt(cs.paddingLeft) + parseInt(cs.paddingRight),
        vertical: parseInt(cs.paddingTop) + parseInt(cs.paddingBottom)
    }
}

export function getMargins(htmlElement: any) {
    const cs = getComputedStyle(htmlElement);
    return {
        top: parseInt(cs.marginTop),
        left: parseInt(cs.marginLeft),
        bottom: parseInt(cs.marginBottom),
        right: parseInt(cs.marginRight),
        horizontal: parseInt(cs.marginLeft) + parseInt(cs.marginRight),
        vertical: parseInt(cs.marginTop) + parseInt(cs.marginBottom)

    }
}