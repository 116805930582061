import { useResizeEvent } from "~/composables/useResizeEvent";
import { useScrollEvent } from "~/composables/useScrollEvent";
import { getMargins, getPaddings } from "./utils";
import { sum } from "~/utils/array";

// resize is global event
const { onResize } = useResizeEvent();

export function useSlider() {
    // resize is local event for each slider
    const { setupScroll, onScroll } = useScrollEvent();

    const imagesColl = reactive<any[]>([]);
    const sliderProps = reactive<any>({});
    const config = reactive<any>({
        options: {
            rows: 2,
            cols: 3
        }
    });

    watch(() => [config, imagesColl], () => updateView(), { deep: true, immediate: true })
    watch(() => imagesColl, () => updateThumbs(), { deep: true, immediate: true })

    function updateView() {
        if (!config.sliderEl) return;

        // updateParams();
        updateSlider();
    }

    function updateParams() {
        // const trackBoundsShouldBe = getTrackBoundsShouldBe();

        // config.trackElWidth = toPixels(trackBoundsShouldBe.width);
        // config.trackElHeight = toPixels(trackBoundsShouldBe.height);
    }

    function getThumbBoundsShouldBe() {
        const thumbHorizMargins = (config.options?.margins * 2) || 0;
        const thumbVertMargins = (config.options?.margins * 2) || 0;

        const thumbWidthShouldBe = (config.sliderEl.offsetWidth / config.options.cols);
        const thumbPercentsShouldBe = thumbWidthShouldBe / config.sliderEl.offsetWidth * 100;
        const thumbHeightShouldBe = thumbWidthShouldBe;

        const thumbModel = {
            width: thumbWidthShouldBe - thumbHorizMargins,
            height: thumbHeightShouldBe - thumbVertMargins,
            widthPercents: thumbPercentsShouldBe,
            margins: config.options.margins * 2
        }

        // console.log('thumbModel', thumbModel)

        return thumbModel;
    }

    function getTrackBoundsShouldBe() {

        const sliderParams = getSliderParams();
        const thumbBoundsShouldBe = getThumbBoundsShouldBe();
        const trackWidthShouldBe = thumbBoundsShouldBe.width * sliderParams.elementsPerRow;
        const trackHeightShouldBe = thumbBoundsShouldBe.height + config.options?.margins;

        return {
            width: trackWidthShouldBe,
            height: trackHeightShouldBe
        }
    }

    function getSliderParams() {
        const itemsCanBeVisible = config.options.rows * config.options.cols;
        const fillVisible = config.options.cols;
        const equalDistrib = Math.round(imagesColl.length / config.options.rows);

        const elementsPerRow = imagesColl.length < itemsCanBeVisible ? fillVisible : equalDistrib;

        return {
            cols: config.options.cols,
            rows: config.options.rows,
            elementsPerRow,
        }
    }

    function updateThumbs() {
        if (!config.sliderEl) return null;

        const thumbShouldBe = getThumbBoundsShouldBe();


        // imagesColl.map(thumb => thumb.style.width = toPercents(thumbShouldBe.widthPercents));
    }

    function updateSlider() {
        const trackBoundsShouldBe = getTrackBoundsShouldBe();
        // config.sliderEl.style.height = toPixels(trackBoundsShouldBe.height * config.options.rows)

        // config.trackEl.style.width = toPixels(trackBoundsShouldBe.width);
        config.trackEl.style.height = toPixels(trackBoundsShouldBe.height * config.options.rows);
    }

    function setConfig(userConfig: any) {
        Object.assign(config, userConfig);
    }

    onScroll((e: any) => {
        // const trackEl = config.sliderEl.querySelector('.scroll-content');

        // // console.log(e);
        // let leftValue = toFloat(config.offsetLeft) + e.deltaX;

        // // validate left side
        // if (leftValue > 0)
        //     leftValue = 0;
        // // validate right side
        // if (leftValue < -sliderProps.sliderWidth + config.sliderEl.clientWidth) {
        //     leftValue = -sliderProps.sliderWidth + config.sliderEl.clientWidth
        // }

        // config.offsetLeft = toPixels(leftValue);
    })

    onResize(() => {
        setTimeout(() => {
            updateParams();
            // updateThumbs();
            updateSlider();
        })
    })

    return {
        imagesColl,
        setConfig,
    }
}