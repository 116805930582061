<script setup>
const props = defineProps(["modelValue"]);
const emit = defineEmits(["update:modelValue"]);
const attrs = useAttrs();

const classObject = ref(null);

watch(
  () => props.modelValue,
  (val) => {
    classObject.value = { active: val };

    //timeout for toast
    if (val === true) setTimeout(() => emit("update:modelValue", false), 3000);
  },
  { immediate: true }
);

function close() {
  emit("update:modelValue", false);
}
</script>
<template>
  <div class="v-toast" :class="classObject" v-bind="attrs">
    <slot>This is short message</slot>
    <v-btn icon="xs" class="ml-1" @click="close()" v-if="attrs.closable">
      <!-- <mdicon name="close" class="flex-inline" /> -->
    </v-btn>
    <div v-else class="ml-2"></div>
  </div>
</template>

<style scoped>
.v-toast {
  position: fixed;
  top: 0;
  left: 50%;
  color: var(--root-white);
  background-color: rgba(5, 7, 6, 0.8);
  border-radius: 5px;
  padding: 10px 10px 10px 20px;
  opacity: 0.3;
  transform: translate3d(-50%, -100%, 0);
  transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
  transition-property: transform, scale, opacity;
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%),
    0px 9px 46px 8px rgb(0 0 0 / 12%);
  font-size: 20px;
  z-index: 9000000;

  white-space: normal;
  width: max-content;
  max-width: 90%;
  line-height: 36px;
  display: inline-flex;
}
.active {
  opacity: 1;
  transform: translate3d(-50%, 30px, 0);
}
</style>

<style scoped>
.error {
  background-color: rgb(255 0 100 / 78%) !important;
  color: var(--text-color);
}

.success {
  background-color: rgb(60 255 215) !important;
  color: #212121;
}

.success svg {
  color: #212121;
}
</style>
