const isToastActive = ref(false)
const toastMessage = ref('');
const toastType: any = ref('');

function toast(text: string, type: string = '') {
    toastMessage.value = transl(text);
    toastType.value = type.toLowerCase();
    isToastActive.value = false;

    setTimeout(() => {
        isToastActive.value = true;
    }, 100);  
}

export function useToast() { 
    return {
        isToastActive,
        toastMessage,
        toastType,
        toast    
    }
}

export {
    isToastActive,
    toastMessage,
    toastType,
    toast
}