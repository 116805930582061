import { useBanners } from "../useBanners";
import { useAds } from "../useAds";
import { useHistory } from "../useHistory";
import { useSettings } from "../useSettings";
import {
    watchHomeScroll,
    watchLanguages,
    watchOutsideRouteNavigation,
    watchParallelScroll,
    watchSettings
} from "./setup.watch";
import { setupBible } from "../useBible";

const loadingAppSettings = ref(true);
const loadingApp = ref(true);

export function useAppSetup() {
    return {
        loadingApp,
        loadingAppSettings,
        useAppStartLoad,
        useAppAfterLoadEffects
    }
}

export async function useAppStartLoad() {

    loadingAppSettings.value = true;

    const { loadSettings, settings } = useSettings();

    // loading settings
    console.log('loading settings ...')
    loadSettings();

    console.log('fetch locales ...');
    await fetchTransl();
    
    console.log('setup locale ...');
    await setLocale(settings.language);

    console.log('setup history ...')
    const { setHistory } = useHistory();
    setHistory(settings.useHistory);

    console.log('fetch bibles and books...');
    await setupBible();

    console.log('setup ads ...');
    const { setupAds } = useAds();
    setupAds();

    loadingAppSettings.value = false;

}

export async function useAppAfterLoadEffects() {
    const { bibles, htmlElements } = useApp();

    const { settings } = useSettings();

    console.log('use bible ...', settings.useBible);
    console.log('loading bible verses');

    await bibles.singleBible.setLocation({
        bibleCode: settings.useBible,
        bookCode: settings.useBook,
        chapterNum: settings.useChapter,
        verseNum: 1
    });

    // scroll to last verse
    scrollToLocation({
        bibleCode: settings.useBible,
        bookCode: settings.useBook,
        chapterNum: settings.useChapter,
        verseNum: 1
    }, htmlElements.singleBible.scrollEl);

    watchSettings();
    watchParallelScroll();
    watchHomeScroll();
    watchLanguages();
    browserMobileHeightFix("--app-height");

    loadingApp.value = false;

    watchOutsideRouteNavigation();
}