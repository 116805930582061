import { defineNuxtPlugin } from '#app/nuxt'
import { LazyVBibleCoursesDialog, LazyVBiblePlanDialog, LazyVBiblePlanResetDialog, LazyVBiblePlansList, LazyVCourseAdsCard, LazyVPlanBibleSelect, LazyVScreenBiblePlans, LazyVBannerCard, LazyVBannerCardAdmin, LazyVCategoryPreviewDialog, LazyVGalleryGroup, LazyVGalleryMenu, LazyVGallerySlider, LazyVImagePreviewDialog, LazyVImagesList, LazyVScreenGallery, LazyVHistoryList, LazyVScreenHistory, LazyVScreenLessons, LazyVScreenParallel, LazyVScreenSelectBible, LazyVScreenSelectBooks, LazyVScreenSelectChapter, LazyVScreenSingleBible, LazyVSearchPanel, LazyVSettingsPanel, LazyVScreenSearch, LazyVSearchChip, LazyVSearchMenu, LazyVSearchDialog, LazyTheFooter, LazyTheHeader, LazyTheMainMenu, LazyVBtn, LazyEffects, LazyVButtonGroup, LazyVCheckbox, LazyVDialog, LazyDialog, LazyVError, LazyVFileSelect, LazyVImageSelect, LazyVImg, LazyVIntersect, LazyVIntersect2, LazyObserveunload, LazyUseIntersectionObserver, LazyUseMutationObserver, LazyVLabel, LazyVLoader, LazyVLoaderRing, LazyVMenu, LazyCssUtils, LazyMenuUtils, LazySelectUtils, LazyVScreen, LazyScreenCsshelpers, LazyUseScreen, LazyUseScreenHooks, LazyVScreenGroup, LazyUseScreenGroup, LazyVScreenTab, LazyScreentabCsshelpers, LazyUseScreenTab, LazyUseScreenTabHooks, LazyVScreenTabs, LazyUseScreenTabs, LazyVSearch, LazyVSegment, LazyVSegmentOld, LazyVSelect, LazyVSlider, LazyUseSlider, LazyUtils, LazyVSvg, LazyVSwitch, LazyVTab, LazyVTabLabel, LazyVTabs, LazyVTabsLabels, LazyUseHooks, LazyUseTabs, LazyVTextArea, LazyVTextField, LazyVToast, LazyVBibleSelect, LazyVBookSelect, LazyVBooksList, LazyVChapterSelect, LazyVChaptersList, LazyVScreenUserSettings, LazyVSettingsBiblePreview, LazyVSettingsFontFamily, LazyVSettingsFontSize, LazyVSettingsSimplePanel, LazyVBibleVerse, LazyVBibleVerseMenu, LazyVDevotionDialog, LazyVHomeButton, LazyVNavigationHeader, LazyVParallelBibleToolbar, LazyVShareSheet, LazyVSingleBible, LazyVSplashScreen, LazyVStrongSheet, LazyVVerseSheet } from '#components'
const lazyGlobalComponents = [
  ["VBibleCoursesDialog", LazyVBibleCoursesDialog],
["VBiblePlanDialog", LazyVBiblePlanDialog],
["VBiblePlanResetDialog", LazyVBiblePlanResetDialog],
["VBiblePlansList", LazyVBiblePlansList],
["VCourseAdsCard", LazyVCourseAdsCard],
["VPlanBibleSelect", LazyVPlanBibleSelect],
["VScreenBiblePlans", LazyVScreenBiblePlans],
["VBannerCard", LazyVBannerCard],
["VBannerCardAdmin", LazyVBannerCardAdmin],
["VCategoryPreviewDialog", LazyVCategoryPreviewDialog],
["VGalleryGroup", LazyVGalleryGroup],
["VGalleryMenu", LazyVGalleryMenu],
["VGallerySlider", LazyVGallerySlider],
["VImagePreviewDialog", LazyVImagePreviewDialog],
["VImagesList", LazyVImagesList],
["VScreenGallery", LazyVScreenGallery],
["VHistoryList", LazyVHistoryList],
["VScreenHistory", LazyVScreenHistory],
["VScreenLessons", LazyVScreenLessons],
["VScreenParallel", LazyVScreenParallel],
["VScreenSelectBible", LazyVScreenSelectBible],
["VScreenSelectBooks", LazyVScreenSelectBooks],
["VScreenSelectChapter", LazyVScreenSelectChapter],
["VScreenSingleBible", LazyVScreenSingleBible],
["VSearchPanel", LazyVSearchPanel],
["VSettingsPanel", LazyVSettingsPanel],
["VScreenSearch", LazyVScreenSearch],
["VSearchChip", LazyVSearchChip],
["VSearchMenu", LazyVSearchMenu],
["VSearchDialog", LazyVSearchDialog],
["TheFooter", LazyTheFooter],
["TheHeader", LazyTheHeader],
["TheMainMenu", LazyTheMainMenu],
["VBtn", LazyVBtn],
["Effects", LazyEffects],
["VButtonGroup", LazyVButtonGroup],
["VCheckbox", LazyVCheckbox],
["VDialog", LazyVDialog],
["Dialog", LazyDialog],
["VError", LazyVError],
["VFileSelect", LazyVFileSelect],
["VImageSelect", LazyVImageSelect],
["VImg", LazyVImg],
["VIntersect", LazyVIntersect],
["VIntersect2", LazyVIntersect2],
["Observeunload", LazyObserveunload],
["UseIntersectionObserver", LazyUseIntersectionObserver],
["UseMutationObserver", LazyUseMutationObserver],
["VLabel", LazyVLabel],
["VLoader", LazyVLoader],
["VLoaderRing", LazyVLoaderRing],
["VMenu", LazyVMenu],
["CssUtils", LazyCssUtils],
["MenuUtils", LazyMenuUtils],
["SelectUtils", LazySelectUtils],
["VScreen", LazyVScreen],
["ScreenCsshelpers", LazyScreenCsshelpers],
["UseScreen", LazyUseScreen],
["UseScreenHooks", LazyUseScreenHooks],
["VScreenGroup", LazyVScreenGroup],
["UseScreenGroup", LazyUseScreenGroup],
["VScreenTab", LazyVScreenTab],
["ScreentabCsshelpers", LazyScreentabCsshelpers],
["UseScreenTab", LazyUseScreenTab],
["UseScreenTabHooks", LazyUseScreenTabHooks],
["VScreenTabs", LazyVScreenTabs],
["UseScreenTabs", LazyUseScreenTabs],
["VSearch", LazyVSearch],
["VSegment", LazyVSegment],
["VSegmentOld", LazyVSegmentOld],
["VSelect", LazyVSelect],
["VSlider", LazyVSlider],
["UseSlider", LazyUseSlider],
["Utils", LazyUtils],
["VSvg", LazyVSvg],
["VSwitch", LazyVSwitch],
["VTab", LazyVTab],
["VTabLabel", LazyVTabLabel],
["VTabs", LazyVTabs],
["VTabsLabels", LazyVTabsLabels],
["UseHooks", LazyUseHooks],
["UseTabs", LazyUseTabs],
["VTextArea", LazyVTextArea],
["VTextField", LazyVTextField],
["VToast", LazyVToast],
["VBibleSelect", LazyVBibleSelect],
["VBookSelect", LazyVBookSelect],
["VBooksList", LazyVBooksList],
["VChapterSelect", LazyVChapterSelect],
["VChaptersList", LazyVChaptersList],
["VScreenUserSettings", LazyVScreenUserSettings],
["VSettingsBiblePreview", LazyVSettingsBiblePreview],
["VSettingsFontFamily", LazyVSettingsFontFamily],
["VSettingsFontSize", LazyVSettingsFontSize],
["VSettingsSimplePanel", LazyVSettingsSimplePanel],
["VBibleVerse", LazyVBibleVerse],
["VBibleVerseMenu", LazyVBibleVerseMenu],
["VDevotionDialog", LazyVDevotionDialog],
["VHomeButton", LazyVHomeButton],
["VNavigationHeader", LazyVNavigationHeader],
["VParallelBibleToolbar", LazyVParallelBibleToolbar],
["VShareSheet", LazyVShareSheet],
["VSingleBible", LazyVSingleBible],
["VSplashScreen", LazyVSplashScreen],
["VStrongSheet", LazyVStrongSheet],
["VVerseSheet", LazyVVerseSheet],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
