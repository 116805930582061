export const adsColl = ref([
    {
        ads_image: 'https://study-api.bibleawr.com/courses/61b51bd4fb83da001caf957c/gd47b4e0c2b4-lg.jpg',
        ads_desc: '10 уроків, які відповідають на питання, що хвилюють сучасну молодь: Чи є справжнє кохання? Чи буде кінець злу, стражданням та смерті? Хто вигадав секс? Як потрапити до неба? Як Тобі зателефонувати? Та інші…',
        ads_locale: 'uk',
        ads_url: 'https://bibleawr.com/course/xfwl'
    },
    {
        ads_image: 'https://study-api.bibleawr.com/courses/60870e43af3c7b001c213458/447b55c2f5ee-lg.jpg',
        ads_desc: '"Дивовижні факти" - міжнародний проект під управлінням "найбагатшої печерної людини" Даґа Бетчелора. Юнаком Даґ відкинув всі можливості, які подарувало йому народження в заможній американській родині, і пустився берега. Опинившись на самісінькому дні, ставши відлюдником у Каліфорнійській печері, Батчелор знаходить запилену Біблію, і його життя перевертається. Колишній хіпі та наркозалежний отримує свободу в Ісусі Христі та посвячує життя для проповіді про його чудеса.',
        ads_locale: 'uk',
        ads_url: 'https://bibleawr.com/course/kpnd'
    },
    {
        ads_image: 'https://study-api.bibleawr.com/courses/60870ed9af3c7b001c213507/2326da02427b-lg.jpg',
        ads_desc: 'Курс «Вірую» розповість про те, як молитва захищає від зла? Яка користь від того, що я знаю Символи віри? Що буде після смерті? Хто такий антихрист? Як уникнути страшного суду? Розуміння цих тем розвіє найпоширеніші забобони та відкриє справжню віру, що здатна пересувати гори.Ісус сказав: «і пізнаєте правду, а правда вас вільними зробить!»',
        ads_locale: 'uk',
        ads_url: 'https://bibleawr.com/course/fsyp'
    },
    {
        ads_image: 'https://study-api.bibleawr.com/courses/609bcb6510f157001c750232/cover.jpg',
        ads_desc: '10 уроков, которые отвечают на вопросы, волнующие современную молодежь: Есть ли настоящая любовь? Будет ли конец злу, страданиям и смерти? Кто придумал секс? Как попасть на небо? Как Тебе позвонить? И другие…',
        ads_locale: 'ru',
        ads_url: 'https://bibleawr.com/course/kdfi'
    },
    {
        ads_image: 'https://study-api.bibleawr.com/courses/60870ec4af3c7b001c2134e5/cover.jpg',
        ads_desc: '"Удивительные факты" - международный проект под управлением "самого богатого пещерного человека" Дага Бетчелора. Юношей Даг отверг все возможности, которые подарило ему рождение в богатой американской семье, и пустился по берегу. Оказавшись на самом дне, став отшельником в Калифорнийской пещере, Батчелор находит запыленную Библию, и его жизнь переворачивается. Бывший хиппи и наркозависимый получает свободу в Иисусе Христе и посвящает жизнь для проповеди о его чудесах.',
        ads_locale: 'ru',
        ads_url: 'https://bibleawr.com/course/onzt'
    },
    {
        ads_image: 'https://study-api.bibleawr.com/courses/6107bc641ac304001c75b56d/cover.jpg',
        ads_desc: 'Курс «Дневник Реформации» наполнен идеями и мотивами, которые важны в жизни каждого человека. Исторический фон помогает увидеть, как обращение к Библии может менять судьбу не только отдельного человека, но и целых народов. Взгляд в прошлое Европы, когда она находилась в жесточайшем кризисе позволяет извлечь уроки веры, надежды и мотивирует изменить на лучшее свою жизнь сегодня.',
        ads_locale: 'ru',
        ads_url: 'https://bibleawr.com/course/dgqo'
    },
    {
        ads_image: 'https://study-api.bibleawr.com/courses/60870e6baf3c7b001c21347d/cover.jpg',
        ads_desc: 'Bible prophecies have always raised many questions, especially from people who do not believe in them. The course "Prophecies of Hope" aims to make you say goodbye to all doubts about their authenticity and realize that the Bible is not only a historical achievement, but also a God-inspired book that has long served as a link between God and man.',
        ads_locale: 'en',
        ads_url: 'https://bibleawr.com/course/iwmn'
    },
    {
        ads_image: 'https://study-api.bibleawr.com/courses/652549dda16fbb001c5401ea/cover.jpg',
        ads_desc: 'Revelation\'s most important prophecies explained in 10-15 minute episodes.',
        ads_locale: 'en',
        ads_url: 'https://bibleawr.com/course/paqn'
    },
    {
        ads_image: 'https://study-api.bibleawr.com/courses/60dc9395bc2c8c001bbc571f/cover.jpg',
        ads_desc: 'The prophecies of the Bible are vivid proof of the Bible\'s authenticity. For example, prediction the fall of world empires, the occurrence of epidemics, wars, and revolutions. This book speaks of the past, present, and near future. International speaker Cami Uthman has traveled the world researching the facts regarding these prophecies. She will tell you exactly how they are coming true today. You will see that they are being fulfilled today faster than ever before in the history of mankind.',
        ads_locale: 'en',
        ads_url: 'https://bibleawr.com/course/bcml'
    }
])