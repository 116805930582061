import { addClass, deleteClass, hasClass, replaceClass } from './screentab.csshelpers'
import { useScreenTabHooks } from './useScreenTabHooks';

export function useScreenTab(el: any) {

    const isTop = () => (hasClass(el.value, 'top'));

    const setTop = () => {
        deleteClass(el.value, 'back');
        addClass(el.value, 'top');
    }

    const setBack = () => {
        deleteClass(el.value, 'top');
        addClass(el.value, 'back');
    }

    const toTop = () => {
        //clear state
        deleteClass(el.value, 'transition', 'back', 'back-before', 'top-before', 'top');
        //start anim state
        addClass(el.value, 'top-before');
        //folow anim
        setTimeout(() => addClass(el.value, 'transition','top'));
    }

    const toBack = () => {
        //clear state
        deleteClass(el.value, 'transition', 'back', 'back-before', 'top-before', 'top');
        //start anim state
        addClass(el.value, 'back-before');
        //folow anim
        setTimeout(() => addClass(el.value, 'transition','back'));
    }

    const makeVisible = () => {
        el.value.style.visibility = 'visible';
    }

    const setDeepIndex = (zIndex: number) => {
        if (el.value)
            el.value.style.zIndex = zIndex;
    }

    const name = el.value.getAttribute('name');

    const tabObject = {
        setDeepIndex,
        isTop,
        setTop,
        setBack,
        toTop,
        toBack,
        makeVisible,
        name,
        el: el.value,
        ...useScreenTabHooks()
    }

    return tabObject;
}
