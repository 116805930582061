import { default as _100vh9p6zCZqzamMeta } from "/data/app/pages/100vh.vue?macro=true";
import { default as _404yQtkCBM216Meta } from "/data/app/pages/404.vue?macro=true";
import { default as adminM816N6AlODMeta } from "/data/app/pages/admin.vue?macro=true";
import { default as historyBt9oHkEdWVMeta } from "/data/app/pages/history.vue?macro=true";
import { default as indexIGRj6npRumMeta } from "/data/app/pages/index.vue?macro=true";
import { default as passwordNotifs8M30DMQ2AlMeta } from "/data/app/pages/passwordNotifs.vue?macro=true";
import { default as registrationq09b2Yp5rtMeta } from "/data/app/pages/registration.vue?macro=true";
import { default as searchH2INDysBWyMeta } from "/data/app/pages/search.vue?macro=true";
import { default as settingssDbr6BwrdRMeta } from "/data/app/pages/settings.vue?macro=true";
import { default as _91_91slug_93_934UZSvloc8uMeta } from "/data/app/pages/shareimg/[[slug]].vue?macro=true";
import { default as siginitycRjDBEAMeta } from "/data/app/pages/sigin.vue?macro=true";
import { default as sliderzAAfj5v7XiMeta } from "/data/app/pages/slider.vue?macro=true";
export default [
  {
    name: "100vh",
    path: "/100vh",
    component: () => import("/data/app/pages/100vh.vue").then(m => m.default || m)
  },
  {
    name: "404",
    path: "/404",
    component: () => import("/data/app/pages/404.vue").then(m => m.default || m)
  },
  {
    name: "admin",
    path: "/admin",
    meta: adminM816N6AlODMeta || {},
    component: () => import("/data/app/pages/admin.vue").then(m => m.default || m)
  },
  {
    name: "history",
    path: "/history",
    component: () => import("/data/app/pages/history.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/data/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "passwordNotifs",
    path: "/passwordNotifs",
    component: () => import("/data/app/pages/passwordNotifs.vue").then(m => m.default || m)
  },
  {
    name: "registration",
    path: "/registration",
    component: () => import("/data/app/pages/registration.vue").then(m => m.default || m)
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/data/app/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/data/app/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: "shareimg-slug",
    path: "/shareimg/:slug?",
    meta: _91_91slug_93_934UZSvloc8uMeta || {},
    component: () => import("/data/app/pages/shareimg/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "sigin",
    path: "/sigin",
    component: () => import("/data/app/pages/sigin.vue").then(m => m.default || m)
  },
  {
    name: "slider",
    path: "/slider",
    component: () => import("/data/app/pages/slider.vue").then(m => m.default || m)
  }
]