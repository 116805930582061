import { useHooks } from "@/composables/useHooks";  

declare let window: any;  

export function useScrollEvent() {  
    const hooks = useHooks();  
    let lastEventParams: any = 0;  
    let touchStart: any = null;  
    let scrollStartTop: number = 0;  
    let timeout: number = 0;  

    function setupScroll(scrollEl: HTMLElement) {  
        const signOfNumber = (num: number) => (num > 0 ? 1 : num < 0 ? -1 : 0);  

        const onScroll = (e: Event) => {  
            if (timeout) {  
                window.cancelAnimationFrame(timeout);  
            }  

            timeout = window.requestAnimationFrame(() => {  
                const currentScrollTop = scrollEl.scrollTop;  
                const offset = currentScrollTop - scrollStartTop;  
                const direction = signOfNumber(offset);  

                if (e.type === 'scroll') {  
                    hooks.invokeHook('mousewheel', {  
                        event: e,  
                        direction: direction,  
                        offset: offset,  
                    });  
                    scrollStartTop = currentScrollTop; // Обновляем начальную позицию прокрутки  
                }  
            });  
        };  

        const onTouchStart = (e: TouchEvent) => {  
            touchStart = e;  
            scrollStartTop = scrollEl.scrollTop; // Сохраняем начальную позицию прокрутки  
        };  

        const onMouseDown = (e: MouseEvent) => {  
            scrollStartTop = scrollEl.scrollTop; // Сохраняем начальную позицию прокрутки  
            touchStart = e; // Сохраняем событие мыши, если это необходимо  
        };  

        const onTouchMove = (e: TouchEvent) => {  
            const offset = e.changedTouches[0].screenY - touchStart.changedTouches[0].screenY;  
            const direction = signOfNumber(offset);  

            if (Math.abs(offset) < 1) return;  

            hooks.invokeHook('mousewheel', {  
                event: e,  
                direction: lastEventParams === 0 ? 0 : direction,  
                offset: offset,  
            });  

            lastEventParams = e;  
        };  

        // Добавляем обработчики событий  
        scrollEl.addEventListener("scroll", onScroll);  
        scrollEl.addEventListener("touchstart", onTouchStart);  
        scrollEl.addEventListener("touchmove", onTouchMove);  
        scrollEl.addEventListener("mousedown", onMouseDown); // Используем отдельный обработчик для мыши  
    }  

    return {  
        setupScroll,  
        onScroll: (hook: any) => hooks.addHook('mousewheel', hook)  
    };  
}