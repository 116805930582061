
export function useIntersectionObserver() {

    // many
    let observerInst: any = null;

    function observeElements({ root, rootMargin, threshold, elements, onintersect }: any = {}) {
        const observerOptions = {
            root: root,
            rootMargin: rootMargin || '0px 0px 0px 0px',
            threshold: threshold || 0
        };

        observerInst = new IntersectionObserver((e) => observerCallbackMany(e), observerOptions)

        if (!elements.length) return;

        const observerCallbackMany = (entries: any) => {
            entries.forEach((entry: any) => {
                if (entry.isIntersecting) onintersect({ element: entry });
            })
        }

        elements.map((el: any) => observerInst.observe(el));
    }

    function unobserveElements({ elements }: any = {}) {
        if (!elements.length || !observerInst) return;
        elements.map((el: any) => {
            if (el)
                observerInst.unobserve(el)
        });
    }

    return {
        observeElements,
        unobserveElements,
    }
}
