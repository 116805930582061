
export function useMutationObserver() {
    let mutationObserver;

    function observeMutaions(params: { container: any, onchange: any }) {
        mutationObserver = new MutationObserver(params.onchange);
        mutationObserver.observe(params.container, { childList: true, subtree: true });            
    }

    function waitOnMutation(element: any, timout: 2000) {
        return new Promise((resolve, reject) => {
            setTimeout(() => resolve(false), timout);
            mutationObserver = new MutationObserver(() => resolve(true));
            mutationObserver.observe(element, { childList: true, subtree: true });      
        })        
    }

    return {
        observeMutaions,
        waitOnMutation
    }
}