import { useAds } from "../useAds";
import { useBible } from "../useBible";
import { useScrollEvent } from "../useScrollEvent";
import { useSettings } from "../useSettings";


export function watchTheme() {
    // watch change theme
    // watch(() => themeClass.value, () => {
    //     settings.themeClass = themeClass.value;
    // })
}

export function watchSettings() {
    const { settings, saveSettings } = useSettings();
    // watch change settings
    watch(() => settings, () => saveSettings(), { deep: true })
}

export function watchLanguages() {
    const { showAnotherAd } = useAds();

    watch(
        () => activeLocale.value,
        () => showAnotherAd()
    )
}

export function watchOutsideRouteNavigation() {
    // watch navigation
    const route = useRoute();

    watch(
        () => route.path,
        () => {
            if (route.query.page) {
                useEvent("route.navigate", route.query.page);
            }
        }, {
        immediate: true
    }
    );
}

export function watchParallelScroll() {
    const { htmlElements, bibles } = useApp();
    const parallelScroll1El = htmlElements.parallelBible1.scrollEl;
    const parallelScroll2El = htmlElements.parallelBible2.scrollEl;

    const parallelScroll1 = useScrollEvent();
    const parallelScroll2 = useScrollEvent();

    if (parallelScroll1El) {
        parallelScroll1.setupScroll(parallelScroll1El);
        parallelScroll1.onScroll(({ event, offset, direction }: any) => {
            if (parallelScroll1El.lock) return;

            // console.log('scroll1')
            syncParallelBibles(parallelScroll1El, parallelScroll2El, {
                sourceBible: bibles.singleBible,
                targetBible: bibles.parallelBible
            });
        })
    }

    if (parallelScroll2El) {
        parallelScroll2.setupScroll(parallelScroll2El);
        parallelScroll2.onScroll(({ offset, direction }: any) => {
            if (parallelScroll2El.lock) return;

            // console.log('scroll2')
            syncParallelBibles(parallelScroll2El, parallelScroll1El, {
                sourceBible: bibles.parallelBible,
                targetBible: bibles.singleBible
            });
        })
    }
}

export function watchHomeScroll() {
    const { htmlElements, appVars } = useApp();
    const scrollEvents = useScrollEvent();

    if (!htmlElements.singleBible.scrollEl) return;

    scrollEvents.setupScroll(htmlElements.singleBible.scrollEl);

    let directionOffset = 0;

    // app header position
    scrollEvents.onScroll(({ event, direction }: any) => {
        if (direction < 0 && directionOffset > 0 ||
            direction > 0 && directionOffset < 0)
            directionOffset = 0;

        directionOffset += direction;

        // console.log(direction, directionOffset)
        appVars.isHeaderVisible = htmlElements.singleBible.scrollEl.scrollTop < 300
            || directionOffset < 100;
        appVars.headerHeightPx = appVars.isHeaderVisible ? '180px' : '135px';
    });

    // chapter position
    const { bibles } = useApp();

    scrollEvents.onScroll(({ event, direction }: any) => updateScrollState());

    function updateScrollState() {
        const elements: any[] = htmlElements.singleBible.scrollEl.querySelectorAll('.v-bible-verse');
        const chapterElem = Array.from(elements)?.find(el =>
            el.offsetTop + el.clientHeight + 0 > htmlElements.singleBible.scrollEl.scrollTop
        )
        if (!chapterElem) return;
        const chapterNumIntoView = chapterElem.getAttribute('chapter-number');

        bibles.singleBible.location.chapterNumIntoView = chapterNumIntoView;
        bibles.singleBible.location.chapterNum = chapterNumIntoView;
        // bibles.singleBible.location.chapterNum = chapterNumIntoView;
    }

    updateScrollState();
}