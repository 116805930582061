<script setup>
const props = defineProps(["loading", "disabled"]);
const attrs = useAttrs();

function handleClick() {
  //if available button group
  buttonGroup?.activate(groupEl);
}

// buttonGroup
const buttonGroup = inject("buttonGroup", null);
const computedClass = computed(() => [active.value ? "active" : ""]);
const active = ref(false);
const groupEl = {
  buttonValue: attrs.value || props.value,
  activeState: active,
};

onMounted(() => buttonGroup?.register(groupEl));
onUnmounted(() => buttonGroup?.unregister(groupEl));
</script>
<template>
  <div
    class="v-btn"
    :class="computedClass"
    :disabled="props.disabled"
    @mousedown.stop="handleClick"
  >
    <slot></slot>
    <slot name="loader">
      <v-loader v-if="props.loading" class="ml-2" size="20px"></v-loader>
    </slot>
  </div>
</template>

<style>
.v-btn {
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
}

.v-btn[disabled="true"] {
  opacity: 0.5;
}

.v-btn:hover {
  opacity: 0.8;
}

.v-btn:active {
  opacity: 0.6;
}
</style>
