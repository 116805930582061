const historyColl = ref<any[]>([]);

export function useHistory() {
    const serializedHistory = ref('');

    function push(refValue: any) {
        const locationRef = `${refValue.bibleModel?.bibleCode}.${refValue.bookCode}.${refValue.chapterNum}`;

        // prevent duplicates
        const lookupHistory = historyColl.value.find(h => h.locationRef == locationRef);
        if (lookupHistory) return;

        const historyModel = {
            bibleShortName: refValue.bibleModel.bibleShortName,
            bookName: refValue.bookModel.bookNameCanonLong,
            chapterNum: refValue.chapterNum,
            location: {
                bibleCode: refValue.bibleModel?.bibleCode,
                bookCode: refValue.bookModel.bookCode,
                chapterNum: refValue.chapterNum,
            },
            locationRef
        }

        historyColl.value.unshift(historyModel);
        serializedHistory.value = JSON.stringify(historyColl.value);
    }

    function clear() {
        historyColl.value = [];
        serializedHistory.value = '';
    }

    function setHistory(historyNewColl: any) {
        historyColl.value = historyNewColl;
    }

    return {
        push,
        clear,
        setHistory,
        historyColl,
        serializedHistory
    }
}