import { loadVerses } from "./bibles.api";
import { locationToString } from "./bibles-render.utils";

export const chaptersMap = ref<any>({});
export const chaptersLoading = ref<any>({});

export const setCache = (key: any, value: any) => chaptersMap.value[key] = value;
export const getCache = (key: any) => chaptersMap.value[key] || null;
export const isCached = (key: any) => chaptersMap.value[key] !== undefined;
export const isLoading = (key: any) => chaptersLoading.value[key];
export const setLoading = (key: any, value: any) => chaptersLoading.value[key] = value;

export async function getVersesFromCacheOrLoad({ bibleCode, bookCode, chapterNum }: any) {
    const cacheKey = locationToString({ bibleCode, bookCode, chapterNum });

    await preloadVerses({ bibleCode, bookCode, chapterNum })

    return getCache(cacheKey);
}

export async function preloadVerses({ bibleCode, bookCode, chapterNum }: any) {
    const cacheKey = locationToString({ bibleCode, bookCode, chapterNum });

    // load current chapter if not loaded
    if (!isCached(cacheKey) && !isLoading(cacheKey)) {
        setLoading(cacheKey, true);

        const versesColl = await loadVerses({ bibleCode, bookCode, chapterNum });
        if (versesColl.length)
            setCache(cacheKey, versesColl);
        
        setLoading(cacheKey, false);

        console.log('cached: ', cacheKey)
        // debugger
    }
    else {
        console.log('block loading', cacheKey)
        // debugger
    }
}