<script setup>
const attrs = useAttrs();

const computedStyle = computed(() => ({
  width: "size" in attrs ? attrs.size : "50px",
}));
</script>
<template>
  <div class="v-loader-wp" :style="computedStyle">
    <div class="v-loader">
      <slot></slot>
      <div class="spinner">
        <!-- <mdicon name="loading" class="icon" /> -->
      </div>
    </div>
  </div>
</template>

<style scoped>
.v-loader-wp {
  pointer-events: none;
}

.v-loader {
  max-width: 200px;
  max-height: 200px;
}
.spinner {
  animation: rotate 0.5s linear infinite;
  width: 100%;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
[center] {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2000;
}
</style>
<style>
.icon svg {
  display: block;
}
</style>
