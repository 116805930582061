import { loadBooksList } from "./bibles.api";

export const booksMap = ref<any>({});

export async function fetchBiblesBooks({ bibleCodes }: any = {}) {
    const books: any[] = await loadBooksList({ bibleCodes });
    const booksGrouppedColl: any[] = groupBy(books, b => b.bibleCode);

    booksGrouppedColl.map(([bibleCode, booksColl]) => booksMap.value[bibleCode] = booksColl)
}

export function findBook({ bibleCode, bookCode }: any) {
    return booksMap.value[bibleCode].find((el: any) => el.bookCode == bookCode);
}

export function findBooksColl({ bibleCode }: any) {
    return booksMap.value[bibleCode];
}