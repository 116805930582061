import { addPlansProgress, getPlansProgressPercents, hasDayInProgress, readPlansProgress, removePlansProgress, storePlansProgress, hasPlanStarted, getLastReadPlanDay, biblePlansProgressColl } from "./bibleplans.progress";

export function useBiblePlansProgress() {

    return {
        readPlansProgress,
        storePlansProgress,
        addPlansProgress,
        removePlansProgress,
        hasDayInProgress,
        hasPlanStarted,
        getLastReadPlanDay,
        getPlansProgressPercents,
        biblePlansProgressColl,
    }
}

readPlansProgress();