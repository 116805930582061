
function copyToClipboard(textToCopy: string) {
   let textArea: any;

   function isOS() {
      //can use a better detection logic here
      return navigator.userAgent.match(/ipad|iphone/i);
   }

   function createTextArea(text: string) {
      textArea = document.createElement('textarea');
      textArea.readOnly = true;
      textArea.contentEditable = true;
      textArea.value = text;
      document.body.appendChild(textArea);
   }

   function selectText() {
      let range: any, selection: any;

      if (isOS()) {
         range = document.createRange();
         range.selectNodeContents(textArea);
         selection = window.getSelection();
         selection.removeAllRanges();
         selection.addRange(range);
         textArea.focus();
         textArea.setSelectionRange(0, 999999);
      } else {
         textArea.select();
      }
   }

   function copyTo() {
      const result = document.execCommand('copy');
      document.body.removeChild(textArea);
      return result;
   }

   createTextArea(textToCopy);

   // store focused elem
   let focusedEl: any = getFocusedElement();

   selectText();

   // restore focused elem
   focusedEl?.focus?.();

   const result = copyTo();
   return result;
}

function getFocusedElement() {
   var focused = document.activeElement;
   if (!focused || focused == document.body)
      focused = null;
   else if (document.querySelector)
      focused = document.querySelector(":focus");
   return focused;
}

export {
   copyToClipboard
}