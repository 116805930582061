import { findBook } from "./bibles-books.utils";
import { getVersesFromCacheOrLoad } from "./bibles-chapters.utils";
import { locationToString, renderMultipleVersesHtml } from "./bibles-render.utils";
import { biblesColl, findBibleTransl } from "./bibles-transl.utils";
import { loading } from "./bibles.api";
import { defaultBible, defaultBook, defaultChapter } from "./defaults";

const makeLocationModel = (bibleCode: string, bookCode: string, chapterNum: number, chapterVerses: any[]) => ({
    bibleCode,
    bookCode,
    chapterNum,
    html: renderMultipleVersesHtml(chapterVerses, { header: true })
});

export function useBible(options?: any) {
    const versesColl = ref<any[]>([]);

    const location = reactive<any>({
        bibleModel: null,
        bookModel: null,
        chapterNum: 0,
        chapterNumIntoView: 0,
        versesHtml: '',
        chaptersHtml: [],
        endOfTheBook: computed(() => {
            const lastVerse = last(versesColl.value);
            return lastVerse?.chapterNum === lastVerse?.bookChapters;
        }),
        chapterTopNum: computed(() => first(versesColl.value)?.chapterNum),
        chapterBottomNum: computed(() => last(versesColl.value)?.chapterNum)
    });

    async function loadChapters(bibleCode: string, bookCode: string, chapterNum: number) {
        const chapterNumPrev = chapterNum - 1;
        const chapterNumNext = chapterNum + 1;

        try {
            const [chapterPrev, chapterCurr, chapterNext] = await Promise.all([
                getVersesFromCacheOrLoad({ bibleCode, bookCode, chapterNum: chapterNumPrev }),
                getVersesFromCacheOrLoad({ bibleCode, bookCode, chapterNum }),
                getVersesFromCacheOrLoad({ bibleCode, bookCode, chapterNum: chapterNumNext })
            ]);

            return [
                chapterPrev ? makeLocationModel(bibleCode, bookCode, chapterNumPrev, chapterPrev) : null,
                chapterCurr ? makeLocationModel(bibleCode, bookCode, chapterNum, chapterCurr) : null,
                chapterNext ? makeLocationModel(bibleCode, bookCode, chapterNumNext, chapterNext) : null
            ].filter(el => el)
                ;
        } catch (error) {
            console.error("Error loading chapters:", error);
            return [];
        }
    }

    async function setLocation({ bibleCode, bookCode, chapterNum }: any = {}) {
        bibleCode = bibleCode || location.bibleModel?.bibleCode || defaultBible;
        bookCode = bookCode || location.bookModel?.bookCode || defaultBook;
        chapterNum = parseInt(chapterNum || location?.chapterNum || defaultChapter);

        location.bibleModel = findBibleTransl({ bibleCode });
        location.bookModel = findBook({ bibleCode, bookCode });
        location.chapterNum = chapterNum;

        location.chaptersHtml = await loadChapters(bibleCode, bookCode, chapterNum);
        console.log('set: ', locationToString({ bibleCode, bookCode, chapterNum }));
    }

    async function showMoreTop({ scrollEl }: any) {

        const chapterModel = first(location.chaptersHtml);
        console.log("showMoreTop,", chapterModel?.chapterNum);

        if (!chapterModel || chapterModel.chapterNum === 1) return;

        const chapterLocation = {
            bibleCode: chapterModel.bibleCode,
            bookCode: chapterModel.bookCode,
            chapterNum: chapterModel.chapterNum - 1
        };

        const topChapter = await getVersesFromCacheOrLoad(chapterLocation);
        if (!topChapter || topChapter.length === 0) return;

        const scrollHeightBefore = scrollEl.scrollHeight; // Высота до добавления новых элементов  

        location.chaptersHtml.unshift(
            makeLocationModel(chapterLocation.bibleCode, chapterLocation.bookCode, chapterLocation.chapterNum, topChapter)
        );

        await new Promise((resolve) => {
            setTimeout(() => {
                const scrollHeightAfter = scrollEl.scrollHeight; // Высота после добавления новых элементов  
                scrollEl.scrollTop = scrollHeightAfter - scrollHeightBefore + 1; // Восстанавливаем положение прокрутки        
                resolve(true);
            })
        })
        await nextTick(); // Ждем обновления DOM
    }

    async function showMoreBottom({ scrollEl }: any) {
        const chapterModel = last(location.chaptersHtml);
        const bookModel = findBook({
            bibleCode: chapterModel.bibleCode,
            bookCode: chapterModel.bookCode
        });
        if (!chapterModel || chapterModel.chapterNum === bookModel.bookChapters) return;

        const chapterLocation = {
            bibleCode: chapterModel.bibleCode,
            bookCode: chapterModel.bookCode,
            chapterNum: chapterModel.chapterNum + 1
        };

        const bottomChapter = await getVersesFromCacheOrLoad(chapterLocation);
        if (!bottomChapter || bottomChapter.length === 0) return;

        location.chaptersHtml.push(
            makeLocationModel(chapterLocation.bibleCode, chapterLocation.bookCode, chapterLocation.chapterNum, bottomChapter)
        );

        await nextTick(); // Ждем обновления DOM  
    }

    return {
        location,
        versesColl,
        biblesColl,
        setLocation,
        showMoreBottom,
        showMoreTop,
        loading,
        options
    };
}