import { first, getSafeIndex, last } from "~/utils/array";

export function useScreenTabs() {
    const screensCollection: any[] = [];
    let history: any[] = reactive([]);

    function register(el: any) {

        if (screensCollection.indexOf(el) >= 0)
            return;

        //setup first screen with class first
        if (screensCollection.length == 0) {
            el.setDeepIndex(1);
            el.setTop();
            history.push(el);
        }
        else {
            el.setBack();
            //screen index were set equal order in collection
            el.index = -screensCollection.length;
        }

        el.makeVisible();
        screensCollection.push(el);
    }

    function unregister(el: any) {
        const index = screensCollection.findIndex(e => e == el);
        screensCollection.splice(index, 1);
    }

    function findByName(name: string) {
        return screensCollection.findIndex(s => s.name == name);
    }

    function goNext(targetIndex?: any, options: any = { history: true }) {

        if (typeof (targetIndex) == "string")
            targetIndex = findByName(targetIndex);

        const currentScreen = last(history);
        const currentIndex = currentScreen.index;

        const expectedIndex = targetIndex || currentIndex + 1;
        if (expectedIndex >= screensCollection.length) return;

        const nextIndex = getSafeIndex(screensCollection, expectedIndex);
        const nextScreen = screensCollection[nextIndex];
        const deepIndex = history.length + 1;

        if (!nextScreen) return;
        nextScreen.setDeepIndex(deepIndex);

        // if (!options?.history)
        history.push(nextScreen);

        currentScreen.toBack();
        nextScreen.toTop();

        nextScreen.invokeOnActivated();
    }

    function goPrev() {
        const [lastScreen] = history.splice(-1, 1);
        goNext(lastScreen.index, { history: false });
    }

    return {
        register,
        unregister,
        currentTab: computed(() => last(history)),
        goNext,
        goPrev,

    }
}