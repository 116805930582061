import { first, last } from "@/utils/array";
import { isObject } from "@/utils/object";

function isSerializedObject(value: String) {
    return (first(value) == '{' && last(value) == '}') ||
        (first(value) == '[' && last(value) == ']');
}

function parseValue(value: any): any {
    if (value === undefined || value === null || value === 'undefined' || value === 'null')
        return null;

    if (value === "true" || value === true)
        return true;

    if (value === "false" || value === false)
        return false;

    if (!isNaN(value) && value.toString().trim() != '')
        return Number.parseFloat(value);

    if (isSerializedObject(value)) {
        return JSON.parse(value);        
    }

    return value;
}

function serializeValue(value: any) {
    return isObject(value)
        ? JSON.stringify(value, null, ' ')
        : value;
}


export {
    parseValue,
    serializeValue
}