import { NUXT_APP_BIBLE_API } from "~/env";
import { useAxios } from "../useAxios";
import { toAppBibleModel, toAppBookModel, toAppVerseModel } from "./bibles.models";

const { axios, loading } = useAxios();

const bibleApi = NUXT_APP_BIBLE_API;

async function loadBiblesList({ locale }: any = {}): Promise<any[]> {
    const params = { locale };
    const { bibles } = await axios.get(bibleApi + `/bibles/list`, { params }) || { bibles: [] }

    // convert to models
    const modelsColl = bibles.map((el: any) => toAppBibleModel({ bible: el }));

    return modelsColl;
}

async function loadBooksList({ bibleCodes }: any) {
    const params = {
        bibles: bibleCodes
    };
    const { books } = await axios.get(bibleApi + `/books/list`, { params }) || { books: [] }

    // convert to models
    const modelsColl = books.map((el: any) => toAppBookModel({ book: el }));

    return modelsColl;
}

async function loadVerses({ bibleCode, bookCode, chapterNum }: any) {
    const params = {
        bible: bibleCode,
        book: bookCode,
        chapter: chapterNum
    }
    // debugger
    const { verses } = await axios.get(bibleApi + `/getverses`, { params }) || { verses: [] }

    // convert to models
    const modelsColl = verses.map((el: any) => toAppVerseModel({ verse: el }));
    
    return modelsColl;
}

export {
    loadBiblesList,
    loadBooksList,
    loadVerses,
    loading
}