import { addClass, deleteClass, hasClass, replaceClass } from './screen.csshelpers'
import { useScreenHooks } from './useScreenHooks';

export function useScreen(el: any) {

    const isCenter = () => (hasClass(el.value, 'center'));

    const setCenter = () => {
        deleteClass(el.value, 'left', 'right', 'movable');
        addClass(el.value, 'center');
    }

    const setRight = () => {
        deleteClass(el.value, 'left', 'center', 'movable');
        addClass(el.value, 'right');
    }

    const setLeft = () => {
        deleteClass(el.value, 'center', 'right', 'movable');
        addClass(el.value, 'left');
    }

    const makeVisible = () => {
        el.value.style.visibility = 'visible';
    }

    const setDeepIndex = (zIndex: number) => {
        if (el.value)
            el.value.style.zIndex = zIndex;
    }

    const toCenter = () => {
        if (hasClass(el.value, 'right'))
            replaceClass(el.value, 'right', 'center');
        else
            replaceClass(el.value, 'left', 'center');

        if (!hasClass(el.value, 'movable'))
            addClass(el.value, 'movable')
    }

    const toLeft = () => {
        if (hasClass(el.value, 'right'))
            replaceClass(el.value, 'right', 'center');
        else
            replaceClass(el.value, 'center', 'left');

        if (!hasClass(el.value, 'movable'))
            addClass(el.value, 'movable')
    }

    const toRight = () => {
        if (hasClass(el.value, 'left'))
            replaceClass(el.value, 'left', 'center');
        else
            replaceClass(el.value, 'center', 'right')

        if (!hasClass(el.value, 'movable'))
            addClass(el.value, 'movable')
    }

    const name = el.value.getAttribute('name');
    const screenObject = {
        setDeepIndex,
        isCenter,
        setCenter,
        setRight,
        setLeft,
        toCenter,
        toLeft,
        toRight,
        makeVisible,
        name,
        el: el.value,
        ...useScreenHooks()
    }

    return screenObject;
}
