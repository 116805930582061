import { adsColl } from "./ads.constants";

const isVisible = ref(false);
const adsModel = ref<any>(null);

export function useAds() {
    const dayMs = 24 * 60 * 1000;
    const twoMinMs = 2 * 60 * 1000;

    function setupAds() {
        updateState();
        setInterval(() => updateState(), dayMs)
        setInterval(() => showAnotherAd(), twoMinMs)
    }

    function suspendAds() {
        const showDate = new Date();
        showDate.setDate(showDate.getDate() + 7);
        localStorage.setItem('adsShowDate', JSON.stringify(showDate));

        isVisible.value = false;
    }

    function showAds() {
        localStorage.setItem('adsShowDate', '');
        isVisible.value = true;
    }

    function getRandomAd() {
        const adsByLocale = adsColl.value.filter(el => el.ads_locale == activeLocale.value);
        const adIndex = Math.floor(Math.random() * adsByLocale.length);
        console.log(adIndex);
        return adsByLocale[adIndex];
    }

    function updateState() {
        const adsShowDateString = localStorage.getItem('adsShowDate') || '';
        const adsShowDate = adsShowDateString ? Date.parse(JSON.parse(adsShowDateString)) : Date.now();

        const isValidDateToShow = adsShowDate <= Date.now();
        const canShow = adsShowDateString ? isValidDateToShow : true;

        if (canShow) {
            adsModel.value = getRandomAd();
            showAds();
        }
    }

    function showAnotherAd() {
        adsModel.value = getRandomAd();
        showAds();
    }

    return {
        adsColl,
        adsModel,
        isVisible,
        setupAds,
        suspendAds,
        showAds,
        showAnotherAd
    }
}