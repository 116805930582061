import { useAxios } from '@/composables/useAxios'
import { NUXT_APP_BIBLE_API } from '~/env';

const { axios, loading } = useAxios();

const yourbibleApi = NUXT_APP_BIBLE_API;

async function createBanner(bannerModel: any) {
    const { banner } = await axios.post(yourbibleApi + `/banners/create`, bannerModel);
    return banner;
}

async function removeBanner(_id: any) {
    const { success } = await axios.delete(yourbibleApi + `/banners/remove/` + _id);
    return success;
}

async function loadBanners() {
    const { banners } = await axios.get(yourbibleApi + `/banners/list`);
    return banners;
}

async function loadBanner({ slug }: any) {
    const { banner } = await axios.get(yourbibleApi + `/banners/byslug/${slug}`);
    return banner;
}

export {
    loading,
    createBanner,
    removeBanner,
    loadBanners,
    loadBanner
}