// v2.1

export function isObject(_object: any) {
    return _object?.__proto__?.constructor.name == 'Object'
}

export function getObjectPropsPaths(modelValue: any): string[] {

    const props = Object.entries(modelValue).flatMap(([prop, propValue]: any) => {
        if (isObject(propValue)) {
            const childProps: any[] = getObjectPropsPaths(propValue);
            return childProps.map(childProp => prop + '.' + childProp);
        }
        return prop;
    });

    return props;
}

export function isEqual(objA: any, objB: any): boolean {
    return JSON.stringify(objA) == JSON.stringify(objB);
}

export function isEmpty(_object: any) {
    return _object === undefined || _object === '' || _object === null
}

export function clone(_object: any): any {
    if (_object?.__proto__?.constructor.name != 'Object') return _object;
    return JSON.parse(JSON.stringify(_object));
}

export function hasProp(_object: any, path: string) {
    const deepRef: any = (obj: any, path: any) => {
        if (path.length == 1) return obj[path];

        const currItem = path.shift();
        return obj[currItem] ? deepRef(obj[currItem], path) : undefined;
    }

    return deepRef(_object, path.split('.'));
}