export function locationToString({ bibleCode, bookCode, chapterNum, verseNum }: any) {
    let location = '';
    if (bibleCode) location += bibleCode + '.';
    if (bookCode) location += bookCode + '.';
    if (chapterNum) location += chapterNum;
    if (verseNum) location += `:${verseNum}`;

    return location;
}

export const chapterTitle = (bookCode: any) => bookCode == "Psa" ? "Psalm" : "Chapter";

export function renderChapterHeadingHtml(verseModel: any) {
    if (!verseModel) return '';

    const locationString = locationToString({
        bibleCode: verseModel?.bibleCode,
        bookCode: verseModel?.bookCode,
        chapterNum: verseModel?.chapterNum,
    });

    const elementId = locationToString({
        bibleCode: verseModel?.bibleCode,
        bookCode: verseModel?.bookCode,
        chapterNum: verseModel?.chapterNum,
    });

    const chapterHtml = `
    <p
        class="chapter-heading"
        elem-id="${elementId}"
        sync-id="${locationToString({
        bookCode: verseModel?.bookCode,
        chapterNum: verseModel?.chapterNum,
    })}"
        chapter-number="${verseModel?.chapterNum}">
        ${transl(chapterTitle(verseModel.bookCode), verseModel.bibleLocale)}
        ${verseModel?.chapterNum}
    </p>`

    return chapterHtml;
}

export function renderVerseHtml(verseModel: any, verseNum: any, options: any) {
    const verseHtml = `
    <div
        class="v-bible-verse"
        elem-id="${locationToString({
        bibleCode: verseModel?.bibleCode,
        bookCode: verseModel?.bookCode,
        chapterNum: verseModel?.chapterNum,
        verseNum: verseModel?.verseNum,
    })}"
        _id="${verseModel?._id}"
        sync-id="${locationToString({
        bookCode: verseModel?.bookCode,
        chapterNum: verseModel?.chapterNum,
        verseNum: verseModel?.verseNum
        // verseNum: verseNum
    })}"
        chapter-number="${verseModel?.chapterNum}"
        draggable="false">
        <span class="chapter-verse">
            <span class="chapter-verse-num">${verseModel.verseNum}</span>
            <span>${verseModel.verseText}</span>
        </span>
    </div>`;

    const chapterHeadingHtml = options?.header
        && verseModel.verseNum == 1
        && verseModel.chapterNum > 1
        ? renderChapterHeadingHtml(verseModel)
        : '';

    return chapterHeadingHtml + verseHtml;
}

export function renderMultipleVersesHtml(versesColl: any[], options?: any) {
    const renderedVersesHtml = versesColl.map((el: any, index: any) => {
        // case when some verses are abscent or moved into another chapter, then
        // to sync we need сontinuous verses numbering
        const verseСontinuousNumbering = index + 1;
        return renderVerseHtml(el, verseСontinuousNumbering, options)
    }).join('');

    return renderedVersesHtml;
}
