import { loadBiblesList } from "./bibles.api";

export const biblesColl = ref<any[]>([]);

export async function fetchBiblesTransl() {
    const bibles = await loadBiblesList();
    const onlyAppBibles = bibles.filter(el => el.bibleApps.includes('mybiblepro'))
    
    biblesColl.value = onlyAppBibles;

    return onlyAppBibles;
}

export function findBibleTransl({ bibleCode }: any) {
    return biblesColl.value.find(el => el.bibleCode == bibleCode);
}