import { loadDictionary, loadLocales } from './translation.api';

const activeLocale = ref('en');
const activeTransl = computed(() => localesColl.value.find(lang => lang.localization_locale == activeLocale.value))
const loadedDics = ref({} as any);
const localesColl = ref<any[]>([] as any);
const localesMap = ref({} as any);

export async function setLocale(locale: string) {
   if (!locale) return;

   await loadLocale(locale);

   activeLocale.value = locale;

   console.log('> set ', locale);

}

export async function loadLocale(locale: string) {

   // break if already loaded
   if (loadedDics.value[locale] !== undefined) return;

   const dictionary = await loadDictionary(locale);
   loadedDics.value[locale] = dictionary.dic || {};

   console.log('loaded locale:', locale);
}

export function transl(key: string, locale?: string) {

   const translLocale = locale || activeLocale.value;
   const dictionary = loadedDics.value[translLocale];

   // return value from dic
   if (dictionary && dictionary[key]) return dictionary[key];

   // return key if dont exists in dic
   return key;
}

export async function fetchTransl() {
   const localesObject: any[] = await loadLocales() || [];
   localesColl.value = Object.values(localesObject);
   localesMap.value = Object.fromEntries(localesObject.map(l => ([l.locale, l])));

   localesColl.value.map(l => loadLocale(l.locale));
}

export function useTranslation() {
   return {
      activeLocale,
      activeTransl,
      localesColl,
      localesMap,
      transl,
      setLocale,
      loadLocale,
   }
}

export {
   activeLocale,
   activeTransl,
   localesColl,
   localesMap
}