function createRipple(event: any) {
  const button = event.currentTarget;
  const diameter = Math.max(button.clientWidth, button.clientHeight);
  const radius = diameter / 2;

  const pos = button.getBoundingClientRect();
  const x = event.clientX - pos.x - (radius / 2);
  const y = event.clientY - pos.y - (radius / 2);

  const ripple = button.getElementsByClassName("ripple")[0];

  if (ripple) {
    ripple.remove();
  }

  const circle: any = document.createElement("span");
  circle.style = `left: ${x}px; top: ${y}px; width: ${radius}px; height: ${radius}px;`;
  circle.classList.add("ripple");

  circle.onanimationend = () => {
    circle.classList.remove("ripple");
    button.removeChild(circle);
  }

  button.appendChild(circle);
}

export {
  createRipple
}