import {
    loading,
    createBanner as _createBanner,
    loadBanners,
    removeBanner,
    loadBanner,
} from "./banners.api";

const bannersColl = ref<any[]>([]);

export function useBanners() {

    async function createBanner(bannerModel: any) {
        const banner = await _createBanner(bannerModel);
        return banner;
    }

    async function fetchBanners() {
        bannersColl.value = await loadBanners();
    }

    function getBannersByCategory(categoryId: string) {
        return bannersColl.value.filter((b) => b.banner_category == categoryId);
    }

    function hasBannersByCategory(categoryId: string) {
        return getBannersByCategory(categoryId)?.length;
    }

    return {
        createBanner,
        removeBanner,
        fetchBanners,
        loadBanners,
        loadBanner,
        getBannersByCategory,
        hasBannersByCategory,
        bannersColl,
        loading
    }
}