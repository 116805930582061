
export function observeElementUnload({ scrollElem, targetClass, unloadPage, onunload }: any) {

    console.log('observeUnload');
    scrollElem.addEventListener("scroll", handleOnScrollUnload, false);

    function handleOnScrollUnload(e: any) {
        if (scrollElem.timeout) window.cancelAnimationFrame(scrollElem.timeout);

        scrollElem.timeout = window.requestAnimationFrame(function () {
            processScroll();
        });
    }

    function processScroll() {
        // console.log(scrollElem.scrollHeight, scrollElem.scrollTop, scrollElem.scrollDirection);

        const childNodesColl = scrollElem.querySelectorAll(targetClass);
        const childElemColl = Array.from<any>(childNodesColl);

        const childTopElemColl = childElemColl.filter(el => (el.offsetTop + el.clientHeight) < scrollElem.scrollTop);
        
        // console.log(childTopElemColl.length);
        if (childTopElemColl.length > unloadPage)
            onunload();

        // scrollElem.scrollDirection = scrollElem.scrollTop > scrollElem.scrollTopPrev ? 'bottom' : 'top';
        scrollElem.scrollTopPrev = scrollElem.scrollTop;
    }
}

