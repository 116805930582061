<script setup>
import { transl } from "@/composables/useTranslation";
import { toastMessage, toastType, isToastActive } from "@/composables/useToast";
import { useAppSetup } from "~/composables/useAppSetup";
import { useRoute } from "vue-router";

function watchAppReady() {
  const { loadingAppSettings } = useAppSetup();

  watch(
    () => loadingAppSettings.value,
    () => {
      if (loadingAppSettings.value) return;
      useAppAfterLoadEffects();
    },
    {
      immediate: true,
    }
  );
}

const { useAppStartLoad, useAppAfterLoadEffects } = useAppSetup();

onBeforeMount(() => {
  useAppStartLoad();
});

onMounted(() => {
  watchAppReady();
});

const seo = {
  title: "Your bible app",
  description: "Your Bible is an app that gives you a fresh spiritual bread on each day.",
  image: "https://mybible.pro/logo.jpg",
};

useServerSeoMeta({
  robots: "index, follow",
  title: seo?.title,
  ogTitle: seo?.title,
  description: seo?.description,
  ogDescription: seo?.description,
  ogImage: seo?.image,
});
</script>
<template>
  <v-toast v-model="isToastActive" :class="[toastType]">
    {{ transl(toastMessage) }}
  </v-toast>

  <Head>
    <Meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <Link rel="apple-touch-icon" sizes="128x128" href="/icons/favicon.svg" />
    <Link rel="icon" type="image/x-icon" href="/icons/favicon.svg" />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:image" content="{IMAGE URL}" />

    <meta property="og:title" content="MyBible.pro" />
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://mybible.pro/" />
    <meta property="og:image" content="{IMAGE URL}" />
    <meta property="og:site_name" content="MyBible.pro" />

    <!-- Додаткові мета-теги -->
    <meta property="og:image:width" content="1080" />
    <meta property="og:image:height" content="1080" />
    <meta property="og:image:url" content="{IMAGE URL}" />
  </Head>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>
