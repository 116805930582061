import { useBible } from "../useBible"
import { useHistory } from "../useHistory";
import { useSelection } from "../useSelection";
import { useSettings } from "../useSettings";
import { removePlansProgress, useBiblePlans } from "../useBiblePlans";
import { useBanners } from "../useBanners";

const bibles = {
    singleBible: useBible(),
    parallelBible: useBible({ isParallel: true }),
}

const selections = {
    singleBible: useSelection()
}

const sheets = reactive({
    shareSheet: {
        sheet: false,
        text: ''
    }
})

const dialogs = reactive<any>({
    plans: {
        dialog: false,
        planModel: null,
    },
    planReset: {
        dialog: false,
        planModel: null
    },
    bibleCourses:{
        dialog: false
    }

})

const htmlElements = reactive({
    appTabsEl: null as any,
    homeScreensEl: null as any,
    parallelScreensEl: null as any,
    todaysScrollEl: null as any,
    singleBible: {
        scrollEl: null,
        screenEl: null
    } as any,
    parallelBible1: {
        scrollEl: null,
        screenEl: null
    } as any,
    parallelBible2: {
        scrollEl: null,
        screenEl: null
    } as any
})

const appVars = reactive({
    isHeaderVisible: true,
    appMenu: false,
    headerHeightPx: '180px'
})

const { settings } = useSettings();

export const settingsComputedClass = computed(() => ({
    'settings-size-lg': settings.fontSize == 'lg',
    'settings-size-md': settings.fontSize == 'md',
    'settings-size-sm': settings.fontSize == 'sm',
    'settings-font-golos': settings.fontFamily == 'golos',
    'settings-font-serif': settings.fontFamily == 'ptserif'
}))

export function useApp() {
    return {
        bibles,
        selections,
        htmlElements,
        appVars,
        sheets,
        dialogs,
        settingsComputedClass
    };
}

useListen('route.navigate', (page: string) => {
    if (page == 'gallery') useEvent('navigation.opengallery')
})

useListen("settings.update", ({ strong, enableVerseParallel, enableVerseNumbers, enableFootnotes }: any) => {
    const { settings } = useSettings();

    settings.enableVerseParallel = enableVerseParallel;
    settings.enableVerseNumbers = enableVerseNumbers;
    settings.enableFootnotes = enableFootnotes;
    settings.enableStrong = strong;
});

// open dialogs
useListen("bible.open", () => {
    htmlElements.appTabsEl.goNext("home");
    htmlElements.homeScreensEl.goHome();
});
useListen("settings.open", () => {
    htmlElements.appTabsEl.goNext("home");
    htmlElements.homeScreensEl.goNext("settings");
});

useListen("history.open", () => {
    htmlElements.appTabsEl.goNext("home");
    htmlElements.homeScreensEl.goNext("history")
});
useListen("parallel.open", async () => {
    const bible1BibleCode = bibles.singleBible.location.bibleModel?.bibleCode;
    const bible2BibleCode = bibles.parallelBible.location.bibleModel?.bibleCode || bible1BibleCode;
    const bible1BookCode = bibles.singleBible.location.bookModel.bookCode;
    const bible1Chapter = bibles.singleBible.location.chapterNum;

    if (!htmlElements.homeScreensEl) return;
    
    htmlElements.homeScreensEl.goNext("parallel")

    await bibles.parallelBible.setLocation({
        bookCode: bible1BookCode,
        chapterNum: bible1Chapter,
    });

    await bibles.parallelBible.setLocation({
        bookCode: bible1BookCode,
        chapterNum: bible1Chapter,
    });

    scrollToLocation({
        bibleCode: bible1BibleCode,
        bookCode: bible1BookCode,
        chapterNum: bible1Chapter
    }, htmlElements.parallelBible1.scrollEl);

    setTimeout(() => {
        syncParallelBibles(
            htmlElements.parallelBible1.scrollEl,
            htmlElements.parallelBible2.scrollEl, {
            sourceBible: bibles.singleBible,
            targetBible: bibles.parallelBible
        });
    })
});
useListen("navigation.opensearch", () => {
    htmlElements.appTabsEl?.goNext("search");
});
useListen("navigation.opengallery", () => {
    htmlElements.appTabsEl?.goNext("gallery");

    const { fetchBanners, bannersColl } = useBanners();
    // if gallery not loaded
    if (!bannersColl.value.length)
        fetchBanners();
});
useListen("navigation.openbibleplans", async () => {
    htmlElements.appTabsEl?.goNext("bibleplans");
});

useListen("bibleplan.read", ({ planModel }: any) => {
    dialogs.plans.planModel = planModel;
    dialogs.plans.dialog = true;
});

useListen('plans.deleteplan', ({ plan }: any) => {
    dialogs.planReset.dialog = true;
    dialogs.planReset.planModel = plan;
})
useListen('plans.ondeleteplan', () => {
    removePlansProgress({ planSlug: dialogs.planReset?.planModel?.plan_slug })
})

useListen("courses.open", () => {
    dialogs.bibleCourses.dialog = true; 
});

//////////////
// single

useListen("singlebible.openlocation", async ({ location }: any) => {
    await bibles.singleBible.setLocation(location);

    openBibleLocation(location, htmlElements.singleBible.scrollEl);
});

useListen('singlebible.selectBible', async ({ singleBible, bibleCode }: any) => {
    await singleBible.setLocation({ bibleCode });

    settings.useBible = bibleCode;
})

useListen('singlebible.selectBook', async ({ singleBible, bookCode }: any) => {

    await singleBible.setLocation({
        bookCode: bookCode,
        chapterNum: 1
    });

    // add location to history
    const history = useHistory();
    history.push(singleBible.location);

    // save settings
    settings.useBook = bookCode;
    settings.useChapter = 1;
    settings.useHistory = history.serializedHistory.value;
})

useListen('singlebible.selectChapter', async ({ singleBible, bookCode, chapterNum }: any) => {
    await bibles.singleBible.setLocation({
        bookCode: bookCode,
        chapterNum: chapterNum,
    });

    scrollToLocation({
        bibleCode: singleBible.location.bibleModel.bibleCode,
        bookCode: bookCode,
        chapterNum: chapterNum,
        verseNum: 1
    }, htmlElements.singleBible.scrollEl);

    // add location to history
    const history = useHistory();
    history.push(singleBible.location);

    // save settings
    settings.useBook = bookCode || settings.useBook;
    settings.useChapter = chapterNum;
    settings.useHistory = history.serializedHistory.value;
})

////////////
// parallel

useListen('parallelbible.selectBible', async ({ singleBible, bibleCode }: any) => {
    await singleBible.setLocation({ bibleCode });

    if (singleBible.options?.isParallel) {
        settings.useParallel = bibleCode;
    }
    else {
        settings.useBible = bibleCode || settings.useBible;
    }
})
useListen('parallelbible.selectChapter', async ({ singleBible, bookCode, chapterNum }: any) => {
    await bibles.singleBible.setLocation({
        bookCode: bookCode,
        chapterNum: chapterNum,
    });

    await bibles.parallelBible.setLocation({
        bookCode: bookCode,
        chapterNum: chapterNum,
    });

    scrollToLocation({
        bibleCode: bibles.singleBible.location.bibleModel.bibleCode,
        bookCode: bibles.singleBible.location.bookModel.bookCode,
        chapterNum: bibles.singleBible.location.chapterNum
    }, htmlElements.parallelBible1.scrollEl);

    // add location to history
    const history = useHistory();
    history.push(singleBible.location);

    // save settings
    if (singleBible.options?.isParallel) {
        settings.useParallel = bookCode || settings.useParallel;

    }
    else {
        settings.useBook = bookCode || settings.useBook;
    }

    settings.useChapter = chapterNum;
    settings.useHistory = history.serializedHistory.value;
})

export function syncParallelBibles(sourceScroll: any, targetScroll: any, { sourceBible, targetBible }: any = {}) {
    const childs1Coll: any[] = Array.from(sourceScroll.querySelectorAll('[sync-id]'));
    const isVisible = (el: any) =>
        // (sourceScroll.scrollTop > el.offsetTop) &&
        (sourceScroll.scrollTop < (el.offsetTop + el.offsetHeight))

    const visibleChild = childs1Coll.find(el => isVisible(el));// || childs1Coll[0];
    if (!visibleChild) return;

    const elemLocation = visibleChild.getAttribute('sync-id');
    const targetElem = targetScroll.querySelector(`[sync-id="${elemLocation}"]`);

    if (!targetElem) return;

    // calc target scroll offset
    const scaleY = targetElem.offsetHeight / visibleChild.offsetHeight;
    const scrollDist = sourceScroll.scrollTop - visibleChild.offsetTop;
    const scrollOffset = targetElem.offsetTop + scrollDist * scaleY;

    if (targetScroll.scrollTop != scrollOffset) {
        targetScroll.scrollTop = scrollOffset;
        targetScroll.lock = true;

        if (targetScroll.timer) clearTimeout(targetScroll.timer);
        targetScroll.timer = setTimeout(() => targetScroll.lock = false, 100)
    }
}
