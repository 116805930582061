import { first, getSafeIndex, last } from "~/utils/array";

export function useScreenGroup() {
    const screensCollection: any[] = [];
    let history: any[] = [];

    function register(el: any) {

        if (screensCollection.indexOf(el) >= 0)
            return;

        //setup first screen with class first
        if (screensCollection.length == 0) {
            el.setDeepIndex(0);
            el.setCenter();
            history.push(el);
        }
        else
            el.setRight();

        el.makeVisible();
        //screen index were set equal order in collection
        el.index = screensCollection.length;
        screensCollection.push(el);
    }

    function unregister(el: any) {
        const index = screensCollection.findIndex(e => e == el);
        screensCollection.splice(index, 1);
    }

    function findByName(name: string) {
        return screensCollection.findIndex(s => s.name == name);
    }

    function goNext(targetIndex?: any) {

        if (typeof (targetIndex) == "string")
            targetIndex = findByName(targetIndex);

        const currentScreen = last(history);
        const currentIndex = currentScreen.index;

        const expectedIndex = targetIndex || currentIndex + 1;
        if (expectedIndex >= screensCollection.length) return;

        const nextIndex = getSafeIndex(screensCollection, expectedIndex);
        const nextScreen = screensCollection[nextIndex];
        const deepIndex = history.length;
        nextScreen.setDeepIndex(deepIndex);

        history.push(nextScreen);

        currentScreen.toLeft();
        nextScreen.toCenter();

        nextScreen.invokeOnActivated();
    }

    function goPrev(count: number = 1) {
        if (history.length < 2) return;

        const step = count - 1;
        const safeStep = step >= history.length ? history.length - 2 : step;
        const currentScreen = history.pop();
        const middleScreens = history.splice(-safeStep, safeStep);
        const nextScreen = last(history);

        middleScreens.map(s => s.setRight())

        currentScreen.toRight();
        nextScreen.toCenter();

        currentScreen.invokeOnDeactivated();
        nextScreen.invokeOnActivated();
    }

    function goHome() {
        if (history.length < 2) return;

        const safeStep = history.length - 2;
        const currentScreen = history.pop();
        const middleScreens = history.splice(-safeStep, safeStep);
        const nextScreen = last(history);

        middleScreens.map(s => s.setRight())

        currentScreen.toRight();
        nextScreen.toCenter();

        currentScreen.invokeOnDeactivated();
        nextScreen.invokeOnActivated();
    }

    return {
        register,
        unregister,
        goNext,
        goPrev,
        goHome,
        getCurrentScreen: () => last(history)
    }
}