import { getBrowserLanguage } from "@/utils/browser";

export const defaultLanguage = 'uk';
export const defaultTheme = 'night';

export const defaultFontSize = 'md';
export const defaultFontFamily = 'ptserif';
export const defaultBible = 'ubo';

export const defaultEnableStrong = false;
export const defaultEnableVerseParallel = true;
export const defaultEnableVerseNumbers = true;
export const defaultEnableFootnotes= true;
