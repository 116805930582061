export function useSelection() {
    
    const isEnabled = ref(false);
    const selectionColl = ref<any[]>([]);
    const selectionLength = computed(() => selectionColl.value.length);

    function addElem(itemValue: any) {
        if (!has(itemValue))
            selectionColl.value.push(itemValue);
        else
            removeElem(itemValue);

        return has(itemValue);
    }

    function removeElem(itemValue: any) {
        const index = selectionColl.value.findIndex(el => el == itemValue);
        if (index == -1) return;

        selectionColl.value.splice(index, 1);
    }

    function clear() {
        selectionColl.value = [];
    }

    function has(itemValue: any) {
        return selectionColl.value.find(el => el == itemValue);
    }

    function turnOn() {
        if (!isEnabled.value) isEnabled.value = true;
    }

    function turnOff() {
        isEnabled.value = false;
    }

    watch(
        () => selectionLength.value,
        () => {
            if (selectionLength.value == 0) turnOff();
        }
    );

    return {
        addElem,
        removeElem,
        has,
        clear,
        turnOn,
        turnOff,
        selectionColl,
        selectionLength,
        isEnabled
    }
}