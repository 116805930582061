import axios from 'axios'

const loading = shallowRef(false);

const axiosGlobal = axios.create({
    // baseURL: import.meta.env.APP_YOURBIBLE_API,
})

const wrapRequest = (request: any, params: any) => {
    loading.value = true;

    return request(...params)
        .then(({ data }: any) => { loading.value = false; return data })
        .catch(() => {
            loading.value = false;
            return {};
        });
}

export function useAxios() {

    const axiosWrapper = {
        get: (...params: any) => wrapRequest(axiosGlobal.get, params),
        post: (...params: any) => wrapRequest(axiosGlobal.post, params),
        put: (...params: any) => wrapRequest(axiosGlobal.put, params),
        delete: (...params: any) => wrapRequest(axiosGlobal.delete, params),
    }

    return {
        axios: axiosWrapper,
        axiosGlobal,
        loading
    }
}