
export const biblePlansProgressColl = ref<any[]>([]);

biblePlansProgressColl.value = readPlansProgress();

export function readPlansProgress() {
    if (!process.client) return;

    const bibleplansString: any = localStorage.getItem('bibleplans');
    return JSON.parse(bibleplansString) || [];
}

export function storePlansProgress(planProgressColl: any[]) {
    if (!process.client) return;
    planProgressColl.sort((a, b) => a.planSlug.localeCompare(b.planSlug) || a.planDay - b.planDay)

    localStorage.setItem('bibleplans', JSON.stringify(planProgressColl));
}

export function addPlansProgress({ planSlug, planDay }: any) {
    const planElemIndex = biblePlansProgressColl.value.findIndex(el => el.planSlug == planSlug && el.planDay == planDay)
    if (planElemIndex == -1) {

        biblePlansProgressColl.value.push({ planSlug, planDay });

        storePlansProgress(biblePlansProgressColl.value);
    }
}

export function removePlansProgress({ planSlug }: any) {
    biblePlansProgressColl.value = biblePlansProgressColl.value.filter(el => el.planSlug != planSlug)

    storePlansProgress(biblePlansProgressColl.value);
}

export function hasDayInProgress({ planSlug, planDay }: any) {
    const planElemIndex = biblePlansProgressColl.value.findIndex(el => el.planSlug == planSlug && el.planDay == planDay)
    return planElemIndex != -1;
}

export function hasPlanStarted({ planSlug }: any) {
    const planElemIndex = biblePlansProgressColl.value.findIndex(el => el.planSlug == planSlug)
    return planElemIndex != -1;
}

export function getPlansProgressPercents({ planSlug, planDays }: any) {
    const days = biblePlansProgressColl.value.filter(el => el.planSlug == planSlug)?.length || 0;
    return Math.round(days / planDays * 100);
}

export function getLastReadPlanDay({ planSlug }: any) {
    const allPlanProgress = biblePlansProgressColl.value.filter(el => el.planSlug == planSlug)
    return last(allPlanProgress)?.planDay || 0;
}