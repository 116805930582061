import { NUXT_APP_BIBLE_IMAGE_URL, NUXT_APP_BIBLE_UI } from "~/env";
import { locationToString } from "../useBible";

export function toSharingText(verses: any[]) {
    const versesText = compileRangesToText(verses);

    const siteUrl = NUXT_APP_BIBLE_UI;
    const copyText = [versesText, siteUrl].join("\n \n");

    return copyText;
}

export function toSharingImage(imageSlug: string) {
    const siteUrl = NUXT_APP_BIBLE_IMAGE_URL;
    const copyText = [siteUrl, imageSlug].join("/");

    return copyText;
}

export function copyVerses(verses: any[]) {
    return copyToClipboard(toSharingText(verses));
}

function getVerseRef(verse: any) {
    return verse.bookNameCanonLong + ' ' + verse.chapterNum + ":" + verse.verseNum;
}

const makeRow = (el: any) => el.verseNum + ' ' + cleanHtmlBibleTags(el.verseText)


function compileRangesToText(verses: any[]) {

    if (verses.length == 1) return getVerseRef(verses[0]) + '\n' + makeRow(verses[0]);

    const books = groupBy(verses, (v: any) => v.bookNameCanonLong)

    const serializedRef = books.map(([book, groupVerses]) => {
        const chapters = groupBy(groupVerses, (v: any) => v.chapterNum)
        const chaptersSorted = sortBy(chapters, c => c[0]);

        const chaptersRangesColl = chaptersSorted.map(([chapter, groupVerses2]) => {
            const compressVerses: any[] = [];
            const groupVersesSorted = sortBy(groupVerses2, g => g.verseNum);

            groupVersesSorted.map((v2: any) => {
                const lastScope = last(compressVerses);

                if (v2.verseNum != lastScope?.end + 1)
                    compressVerses.push({
                        start: v2.verseNum,
                        end: v2.verseNum
                    })
                else
                    lastScope.end = v2.verseNum;
            })

            const versesRangesColl = compressVerses.map(vg => {
                return vg.start != vg.end
                    ? vg.start + '-' + vg.end
                    : vg.start
            })

            return chapter + ':' + versesRangesColl.join(',');
        });

        // serialize chapters and verses
        const serializedBookRef = book + ' ' + chaptersRangesColl.join('; ');
        const serializedVerses = chaptersSorted.map(([_, verses]) => {
            const versesSorted = sortBy(verses, v => v.verseNum);

            return versesSorted.map(el => makeRow(el)).join('\n');
        }).join('\n');

        // serialize books
        return serializedBookRef + '\n' + serializedVerses;
    })

    // serialize all refs
    return serializedRef.join("\n\n");
}

export function openBibleLocation(verseModel: any, scrollEl: any) {
    const { htmlElements } = useApp();

    htmlElements.appTabsEl.goNext("home");
    htmlElements.homeScreensEl.goHome();

    const verseSelector = locationToString({
        bibleCode: verseModel.bibleCode,
        bookCode: verseModel.bookCode,
        chapterNum: verseModel.chapterNum,
        verseNum: verseModel.verseNum,
    });

    scrollToVerse(verseSelector, scrollEl);
}

export function cleanHtmlBibleTags(html: string) {
    return html
        //Strong numbers
        .replace(/<S>[^<]+<\/S>/gi, '')
        .replace(/  /g, ' ')
        .replace(/(<([^>]+)>)/ig, "")
        .trim();
}

export function scrollToLocation(verseModel: any, scrollEl: any) {
    const verseSelector = locationToString({
        bibleCode: verseModel.bibleCode,
        bookCode: verseModel.bookCode,
        chapterNum: verseModel.chapterNum,
        verseNum: verseModel.verseNum,
    });

    scrollToVerse(verseSelector, scrollEl);
}

async function scrollToVerse(verseRef: any, scrollEl: any) {
    if (!scrollEl) return;
  
    await nextTick();
      const verseEl = scrollEl.querySelector(`[elem-id="${verseRef}"]`);
      if (!verseEl) return;

    await nextTick();
    setTimeout(() => scrollEl.scrollTop = verseEl.offsetTop, 100)
}
