let NUXT_APP_BIBLE_UI = "mybible.pro";
let NUXT_APP_BIBLE_API = "https://api.mybible.pro";
let NUXT_APP_BIBLE_IMAGE_URL = "https://mybible.pro/shareimg";
let NUXT_APP_SERVICE_API_TOKEN = '76023adcae87a794e30540b970c9817081b5f440';
let NUXT_APP_TOKEN = '876d76b994e5fc29cdcc53486009f846eda1e904';

if (process.dev) {
    // NUXT_APP_BIBLE_API = "http://localhost:3008";
    // NUXT_APP_BIBLE_API = "http://192.168.0.102:3008";
}

export { 
    NUXT_APP_BIBLE_UI,
    NUXT_APP_BIBLE_API,
    NUXT_APP_BIBLE_IMAGE_URL,
    NUXT_APP_SERVICE_API_TOKEN,
    NUXT_APP_TOKEN
}