async function loadLocales() {
    const locales = [
        {
            locale: 'uk',
            locale_name: 'Ukrainian',
            locale_origin: 'Українська',
        },
        {
            locale: 'ru',
            locale_name: 'Russian',
            locale_origin: 'Русский',
        },
        {
            locale: 'en',
            locale_name: 'English',
            locale_origin: 'English',
        }
    ]
    return locales;
}

async function loadDictionary(localeCode: string) {
    const dics: any = {
        uk: (await import('./dics/uk')).default,
        ru: (await import('./dics/ru')).default,
        en: (await import('./dics/en')).default
    }
    const localeModel = {
        locale: localeCode,
        dic: dics[localeCode]
    }

    return localeModel;
}

export {
    loading,
    loadLocales,
    loadDictionary,
}